import React, { Component } from 'react'
import { Link } from 'gatsby'
import formStyles from './form.module.scss'

class Form extends Component {
    
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        //this.toggleContent = this.toggleContent.bind(this)
        this.state = {
            showForm: true
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const data = new FormData(e.target)
        let response

        fetch('https://forms.host3.co.uk/?dachagg@gmail.com', {
            method: 'POST',
            body: data,
        })
        .then(response => response.text())
        .then(data => {
            response = data

            if (data.includes("Thank")) {
                this.setState({
                    showForm: !this.state.showForm
                })
            }
            console.log('Success:', data)
        })
        .catch((error) => {
            console.error('Error:', error)
        })
    }

    render() {

        const formHidden = this.state.showForm ? `${formStyles.form__show}` : `${formStyles.form__hide}`

    return (
        <div className={formStyles.form}>

            <div className={formStyles.form__existing}>
                <p>Are you an existing customer?</p>
                
                <Link to="/support/" className="button button--small button__transparent">Contact Support</Link>

            </div>

            <div className={formHidden}>

                <form className={"container " + formStyles.form__container} onSubmit={this.handleSubmit}>

                    <input type="text" name="name" placeholder="Full name*" />

                    <input type="email" name="email" placeholder="Email address*" />

                    <input type="text" name="website" placeholder="Website*" />

                    <input type="hidden" name="_subject" value="Host3 Enquiry" />

                    <input type="text" name="_gotcha" style={{display : "none"}} value="" />

                    <textarea name="message" placeholder="Message"></textarea>

                    <button className="button">Send Message</button>

                </form>

            </div>
        
        </div>
    )
    }
}

export default Form