import React from 'react'

import Store from '../components/store'
import Head from '../components/head'
import Navigation from '../components/navigation'
import Header from '../components/header'
import SubHeader from '../components/sub-header'
import Form from '../components/form'
import Logos from '../components/logos'
import Talk from '../components/lets-talk'
import Footer from '../components/footer'
import Cookies from '../components/cookies'

const Contact = () => {
    return (
        <Store>
            <Head title="Contact" />
            <Navigation />
            <Header disable />
            <SubHeader title="Contact" span="Us" />
            <Form />
            <Logos />
            <Talk />
            <Footer />
            <Cookies />
        </Store>
    )
}

export default Contact
